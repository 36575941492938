import { PlannerWithDaysAndRecipes } from "@/types/planner";

interface FetchGetPlannersByUserIdOptions {
  includeRecipes?: boolean;
  isHidden?: boolean;
}

async function fetchGetPlannersByUserId(
  userId: string,
  options: FetchGetPlannersByUserIdOptions = {}
): Promise<PlannerWithDaysAndRecipes[]> {
  const { includeRecipes = true, isHidden = false } = options;

  const queryParams = new URLSearchParams({
    userId,
    includeRecipes: includeRecipes.toString(),
    isHidden: isHidden.toString(),
  });

  const response = await fetch(`/api/planner/get?${queryParams.toString()}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    console.error("Error fetching planners:", errorData.error);
    throw new Error(errorData.error);
  }

  const data = await response.json();

  if (!data.success) {
    console.error("Error fetching planners:", data.error);
    throw new Error(data.error);
  }

  console.log("Planners fetched successfully");
  return data.planners;
}

export default fetchGetPlannersByUserId;
