import clsx from "clsx";
import { Minus, Plus } from "react-feather";
import { useState } from "react";

const Counter = ({
  startingNumber,
  onChange,
}: {
  startingNumber: number;
  onChange: (newNumber: number) => void;
}) => {
  const [number, setNumber] = useState(startingNumber);
  const handleServingSizeChange = (direction = 1) => {
    const newNumber = number + direction;

    if (newNumber > 0 && newNumber < 9) {
      setNumber(newNumber);
      onChange(newNumber);
    }
  };

  return (
    <>
      <div
        className={clsx(
          "group flex items-center justify-center gap-1 rounded-full border bg-gray-100 p-1"
        )}
      >
        <_Control direction={-1} onClick={handleServingSizeChange} />

        <div
          className={clsx(
            "flex size-6 items-center justify-center text-center font-body text-sm font-medium tabular-nums"
          )}
        >
          <div className="">{number}</div>
        </div>

        <_Control direction={1} onClick={handleServingSizeChange} />
      </div>
    </>
  );
};

const _Control = ({ direction, onClick }) => {
  const handleClick = () => {
    onClick(direction);
  };

  const Icon = direction === -1 ? Minus : Plus;

  return (
    <button
      onClick={handleClick}
      className={clsx("flex items-center justify-center rounded-full bg-black")}
    >
      <Icon className={clsx("size-6 text-white")} />
    </button>
  );
};

export default Counter;
